<template>
  <section>
    <div class="wrapper">
      <div class="logo">
        <router-link to="/">
          <img class="lowco-logo" src="@/assets/images/logo-lowco.png" alt="Lowco logo" />
        </router-link>
      </div>
      <FontAwesomeIcon class="success-icon" :icon="faCheckCircle" />
      <div>
        <p class="tac">Enregistrement réussi !</p>
        <p class="tac">Un mail vous a été envoyé afin de définir votre mot de passe.</p>
      </div>
      <button class="lowco-button login-button" @click="goToLogin">
        Retourner vers la page de connexion
      </button>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const router = useRouter();

    const goToLogin = () => {
      router.push('/signIn');
    };

    return {
      faCheckCircle,
      goToLogin,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/variables.scss";
@import "../assets/styles/common/mixins.scss";

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;

  padding: 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $alternate-green;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(#000, .10);

  @include ipad {
    min-width: 80rem;
    width: auto;
    padding: 5rem 10rem;
  }
}

.logo {
  max-width: 30rem;
  min-width: 20rem;
}

.success-icon {
  font-size: 10rem;

  margin: 4rem;
}

.login-button {
  margin-top: 5rem;
  width: auto;
  padding: 1rem 3rem;
  font-size: 1.6rem;

  @include sm {
    padding: 0.4rem 2rem;
    font-size: 2rem;
  }
}
</style>
